//const
import { LANGUAGE_LOCAL_STOAGE, PL, UA, EN } from './const'

export const translation = keyFromText => {

    const langKeyFronLocalStorage = localStorage.getItem(LANGUAGE_LOCAL_STOAGE)
    // if no set which language in local storage
    if (!langKeyFronLocalStorage) { return keyFromText }

    const langJson = JSON.parse(localStorage.getItem(langKeyFronLocalStorage))
    // if no JSON with chosen language in local storage
    if (!langJson) { return keyFromText }

    const textToReturn = langJson[keyFromText]
    // if no such a key
    if (!textToReturn) { return '_' + keyFromText }

    return textToReturn
}