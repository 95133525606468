import React from 'react'


// img
import elephant from '../assets/NFT/dovima_with_elephants.jpg'
import monalisa from '../assets/NFT/monalisa.jpg'
import apeYachtClub from '../assets/NFT/BoredApeYachtClub.png'
import cryptopunks from '../assets/NFT/cryptopunks.png'
import { ReactComponent as ExternalLink } from '../assets/icons/external-link.svg'

// translation
import { translation } from '../utils/translation'


const Nft = () => {

    return (
        <section className='screen'>


            <h3 className='title'>{translation('Co to jest NFT?')}</h3>
            <p className='text margin1UpDown'>{translation('NFT (Non-Fungible Token) można przetłumaczyć jako token niemający zamienników. Stoi on w opozycji do zamienialnych tokenów znanych nam z życia codziennego. Typowym przykładem tokenu zamienialnego jest moneta reprezentująca pewną wartość w walucie. Np. każda moneta o nominale 1 zł ma swoje zamienniki w postaci innych monet 1-złotowych. Jeśli dwie osoby wymienią się taką zamienialną monetą, nic się nie zmieni.')}</p>
            <p className='text text_green margin1UpDown'>{translation('Alicja i Borys mają w portfelu po jednej monecie o nominale 1 zł. Gdy zamienią się monetami, w portfelach nadal będą mieli po 1 zł, a monety są nierozróżnialne między sobą.')}</p>
            <p className='text margin1UpDown'>{translation('Niezamienialne tokeny (NFT) rządzą się innymi prawami. Każdy taki token jest unikalny i nie posiada zamiennika. Wyobraźmy sobie limitowaną serię stu odbitek jakiegoś zdjęcia, gdzie każda odbitka jest numerowana ręcznie przez autora. Ma znaczenie, czy jesteś w posiadaniu odbitki sygnowanej numerem #1, #20, czy może #100. Każda z tych odbitek może mieć inną wartość rynkową nadaną przez prawa popytu i podaży. Jeśli komuś bardziej zależy na posiadaniu odbitki #1, niż odbitki #6, to będzie skłonny kupić odbitkę #1 drożej, niż tę z numerem #6. Nawet jeśli dwie odbitki mają taką samą wartość rynkową, to jest to sytuacja tymczasowa i w przyszłości wartość każdej odbitki może być inna - dlatego zawsze ma znaczenie, właścicielem której odbitki jesteś. Wspomniane odbitki są właśnie przykładem niezamienialnych tokenów, czyli NFT.')}</p>
            <div className='nft__img_container'>
                <img className='img' src={elephant} alt='elephant' />
            </div>
            <p className='text_small margin1UpDown'>{translation('Dovima with elephants – Richard Avedon (#46/100)')}</p>
            <p className='text margin1UpDown'>{translation('NFT nie muszą być seriami. Wyobraźmy sobie obraz Mona Lisy pędzla mistrza Leonarda. Jest on również przykładem NFT. Jest tylko jeden taki obraz i ma on jednego właściciela, nie posiadając żadnego zamiennika. Każda kopia Mona Lisy, choćby lepsza technicznie od samego oryginału, pozostaje tylko kopią i nie może być traktowana jako zamiennik. Żaden właściciel oryginału Mona Lisy nie chciałby się zamienić z kimś i otrzymać w zamian, nawet najbardziej fantastyczną, kopię tego dzieła.')}</p>
            <div className='nft__img_container'>
                <img className='img' src={monalisa} alt='monalisa' />
            </div>
            <p className='text_small margin1UpDown'>{translation('Mona Lisa – Leonardo da Vinci')}</p>
            <p className='text margin1UpDown'>{translation('Dziś pojęcie NFT odnosi się właściwie wyłącznie do tokenów cyfrowych, które mogą mieć swój odpowiednik w świecie fizycznym, ale nie muszą. Coraz częściej NFT dotyczą dóbr w pełni cyfrowych, takich jak obrazy, zdjęcia, wideo czy karty kolekcjonerskie. Rynek takich dóbr przeżywa obecnie prawdziwy boom.')}</p>
            <div className='nft__img_container'>
                <img className='img' src={apeYachtClub} alt='apeYachtClub' />
            </div>
            <p className='text_small margin1UpDown'>{translation('Bored Ape Yacht Club #7246/10000')}</p>
            <p className='text text_red margin1UpDown'>{translation('W czwartek 28 października 2021 roku obraz z serii CryptoPunks sygnowany numerem #9998 (dostępnych jest 10000 obrazów w serii) został kupiony za ponad 124 tysiące ETH (równowartość ok. 532 000 000 dolarów). Eksperci podejrzewają, że transkacja przebiegła między dwoma partfelami tej samej osoby i że był to wyszukany chwyt marketingowy, mający na celu podniesienie wartości NFT.')}</p>
            <div className='nft__img_container'>
                <img className='img' src={cryptopunks} alt='cryptopunks' />
            </div>
            <p className='text_small margin1UpDown'>{translation('CryptoPunks #9998/10000')}</p>


            <h3 className='title margin2Up'>{translation('Blockchain')}</h3>
            <p className='text margin1UpDown'>{translation('Z technicznego punktu widzenia, za NFT stoi technologia blockchain, dzięki której od kilkunastu lat dokonuje się także rewolucja kryptowalutowa. Wszystkie transakcje zapisywane są w specjalnym dzienniku. Z wykorzystaniem technik kryptograficznych do każdej transakcji wprowadza się ślad wszystkich poprzednich transakcji z całej historii dziennika, który utrzymywany jest na wielu komputerach w sieci (węzły). Podczas akceptowania transakcji węzły rozmawiają ze sobą i ustalają, jak będzie wyglądał nowy dziennik po akceptacji transakcji (biorąc pod uwagę: (a) liczbę węzłów głosujących na dany dziennik jak i (b) liczbę wszystkich transakcji w dzienniku). Proces ustalania między węzłami oraz utrzymania samego dziennika jest kosztowny, dlatego węzły są przez sieć wynagradzane w kryptowalucie obowiązującej w danej sieci. Kryptowaluta ta służy również do uiszczania opłat za transakcje w ramach sieci.')}</p>
            <p className='text text_red margin1UpDown'>{translation('Najstarsza sieć blockchain, Bitcoin, została publicznie uruchomiona w 2009 roku. Wartość tokena Bitcoin utrzymywała się przez dwa lata nieznacznie powyżej 0$. W 2011 roku osiągnęła pułap 1$ za sprawą organizacji pozarządowej Electronic Frontier Foundation, która przez kilka miesięcy przyjmowała darowizny w tej kryptowalucie. W listopadzie 2021 roku 1 Bitcoin osiągnął wartość 67000$.')}</p>
            <p className='text margin1Up'>{translation('Technologia blockchain posiada kilka cech, które czynią ją wyjątkową.')}</p>
            <ul className='text nft__middle_ul'>
                <li><span className='textBold'>{translation('Transparentność')}</span> {translation(' – historia transakcji jest w pełni jawna.')}</li>
                <li><span className='textBold'>{translation('Anonimowość')}</span> {translation(' – użytkownicy identyfikowani są za pomocą numeru lub nazwy portfela.')}</li>
                <li><span className='textBold'>{translation('Niezależność')}</span> {translation(' – za sieć odpowiadają właściciele węzłów, czyli cała społeczność.')}</li>
                <li><span className='textBold'>{translation('Bezpieczeństwo')}</span> {translation(' – sieć jest odporność na cyberataki, a zapis w dzienniku jest nieodwracalny.')}</li>
            </ul>
            <p className='text margin1Up'>{translation('Technologia ta ma także pewne słabe strony.')}</p>
            <ul className='text nft__middle_ul'>
                <li><span className='textBold'>{translation('Koszty transakcji')}</span> {translation(' – im więcej węzłów w sieci oraz im łańcuch jest dłuższy, tym transakcja jest droższa.')}</li>
                <li><span className='textBold'>{translation('Efektywność')}</span> {translation(' – przy długich łańcuchach i bardzo rozproszonej sieci, czas trwania transakcji wydłuża się, np. w sieci Bitcoin wynosi on ok. 10 minut.')}</li>
                <li><span className='textBold'>{translation('Wpływ na ekologię')}</span> {translation(' – utrzymanie sieci blockchain zużywa bardzo dużo energii.')}</li>
                <li><span className='textBold'>{translation('Skalowalność')}</span> {translation(' – wraz ze wzrostem sieci, znacząco rośnie zużycie przestrzeni dyskowej i energii oraz poziom skomplikowania przy synchronizowaniu danych.')}</li>
                <li><span className='textBold'>{translation('Regulacje prawne')}</span> {translation(' – zdecentralizowane systemy nie mają jednej osoby lub instytucji, która mogłaby odpowiadać za daną sieć przed sądem, społeczność sieci jest międzynarodowa, trudno więc stosować prawo obowiązujące w konkretnym kraju.')}</li>
                <li><span className='textBold'>{translation('Anonimowość')}</span> {translation(' – trudność w identyfikacji osoby stojącej za nielegalną transakcją.')}</li>
            </ul>
            <p className='text margin1Up'>{translation('Technologia blockchain rozwija się obecnie niezwykle prężnie. Wprowadzane są nowe wersje, powstają nowe sieci. Znajduje ona swoje zastosowanie w wielu obszarach takich jak:')}</p>
            <ul className='text nft__middle_ul'>
                <li>{translation('finanse')}</li>
                <li>{translation('rynek sztuki')}</li>
                <li>{translation('gry online')}</li>
                <li>{translation('prawa autorskie')}</li>
                <li>{translation('identyfikacja osobowa')}</li>
                <li>{translation('zarządzanie łańcuchem dostaw')}</li>
                <li>{translation('rynek nieruchomości')}</li>
                <li>{translation('bezpieczeństwo danych medycznych')}</li>
                <li>{translation('systemy głosowania')}</li>
                <li>{translation('Internet rzeczy (IoT)')}</li>
            </ul>
            <p className='text margin1UpDown'>{translation('Blockchain wymieniany jest wśród trzech podstaw trzeciej rewolucji internetowej. Trójkę uzupełniają AI (sztuczna inteligencja) i IoT (Internet rzeczy).')}</p>
        </section>
    )
}

export default Nft
