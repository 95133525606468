import React, { useEffect } from 'react'

// translation
import { translation } from '../utils/translation'

// spinner
import Spinner from '../UI/Spinner/Spinner'

// img
import backgrounHome from '../assets/Home/backgrounHome.png'
import years20 from '../assets/Home/20years.png'
import clock from '../assets/Home/clock.png'
import trophy from '../assets/Home/trophy.png'

import stage_00 from '../assets/Home/stage_00@2x.png'
import stage_01 from '../assets/Home/stage_01@2x.png'
import stage_02 from '../assets/Home/stage_02@2x.png'
import stage_03 from '../assets/Home/stage_03@2x.png'
import stage_04 from '../assets/Home/stage_04@2x.png'
import stage_05 from '../assets/Home/stage_05@2x.png'
import stage_06 from '../assets/Home/stage_06@2x.png'
import stage_07 from '../assets/Home/stage_07@2x.png'
import stage_08 from '../assets/Home/stage_08@2x.png'

// svg
import { ReactComponent as InfoIcon } from '../assets/icons/info-circle.svg'


const Home = ({ visitedStations, visitedStationsWithSections, visitedStationsSpinner, accounts, network, balance, setSreenNumber, coidIdList, coidId, isOwnerCoidId, addCoinDataFromContract, setIsNftScreenVisible, isCoinAvailableInSecretary }) => {

    // auto scrool to top if last station
    useEffect(() => { visitedStations.every(i => i === true) && window.scrollTo({ top: 0, behavior: "smooth" }) }, [visitedStations])



    const setCoinViewAccordingToWhichStationsWasVisited = () => {

        // check hw many full sections of stations was visited
        let howManyFullSectionsIsVisited = 0
        visitedStationsWithSections.forEach(section => {
            const isFullSectionVisited = section.stations.every(station => station.isVisited === true)
            howManyFullSectionsIsVisited = isFullSectionVisited ? howManyFullSectionsIsVisited + 1 : howManyFullSectionsIsVisited
        })

        // if coin is minted
        if (+coidId > 0) {
            howManyFullSectionsIsVisited = 9
        }

        // set coinView
        let coinView = stage_00
        switch (howManyFullSectionsIsVisited) {
            case 0:
                coinView = stage_00
                break;
            case 1:
                coinView = stage_01
                break;
            case 2:
                coinView = stage_02
                break;
            case 3:
                coinView = stage_03
                break;
            case 4:
                coinView = stage_04
                break;
            case 5:
                coinView = stage_05
                break;
            case 6:
                coinView = stage_06
                break;
            case 7:
                coinView = stage_07
                break;
            case 8:
                coinView = stage_08
                break;
            case 9:
                coinView = stage_08
                break;
            default:
                coinView = stage_00
                break;
        }
        return coinView
    }

    const changeCoinView = (action, coidId) => {
        const currentShownCoinFromList = coidIdList.findIndex(i => i === coidId)
        console.log('currentShownCoinFromList: ', currentShownCoinFromList);
        if (action = 'increasse') {
            const newItemOnListIndex = (coidIdList.length - 1) > currentShownCoinFromList ? currentShownCoinFromList + 1 : 0
            addCoinDataFromContract(`${coidIdList[newItemOnListIndex]}`)
        } else {
            const newItemOnListIndex = currentShownCoinFromList === 0 ? (coidIdList.length - 1) : currentShownCoinFromList - 1
            addCoinDataFromContract(`${coidIdList[newItemOnListIndex]}`)
        }
    }

    return (
        <div style={{ width: '100%' }}>


            {/* first view  */}
            <section className='home__first_screen_container fullScreanSize'>

                {/* background */}
                <figure className='home__first_screen_container_background'>
                    <img className='home__first_screen_container_background_img' src={backgrounHome} alt='backgrounHome' />
                </figure>

                {/* description show according coinID is minted  */}
                <div className='home__info_container'>
                    {+coidId !== 0
                        ? <div className='home__info_container_price'>
                            <p className='home__info_container_coin_text_top text text_center margin1UpDown'>{!isOwnerCoidId ? translation('To już koniec naszej zabawy. Moneta NFT z jubileuszowej kolekcji 01AIC21 była kiedyś Twoja, ale teraz należy do kogoś innego.') : translation('Gratulacje! To już koniec naszej zabawy. Jedna z 1021 cyfrowych monet NFT z jubileuszowej kolekcji 01AIC21 jest Twoja.')}</p>
                            <div className='home_coinIdName'>
                                {coidIdList.length > 1 && <div onClick={() => changeCoinView('decreasse', coidId)} className='home_arrow_left'></div>}
                                <h3 className='home__info_container_coin_text_bottom title text_very_big'>#{coidId}</h3>
                                {coidIdList.length > 1 && <div onClick={() => changeCoinView('increasse', coidId)} className='home_arrow_right'></div>}
                            </div>
                        </div>
                        : <figure className='home__info_container_figure_small'>
                            <img className='img' src={years20} alt='years20' />
                        </figure>
                    }
                </div>

                {/* image  */}
                <figure className='home__info_container_figure_main'>
                    <img
                        className='home__info_container_figure_main_img'
                        src={setCoinViewAccordingToWhichStationsWasVisited()}
                        alt='mainCoin'
                        style={(+coidId !== 0 && !isOwnerCoidId) ? { opacity: '0.2' } : {}} // turn of if is not coin owner
                    />
                </figure>


                {/* description show if visited all stations (20) but coinID wan't be minted yet */}
                {network && (+coidId === 0) && visitedStations.every(i => i === true)
                    && <div className='home__info_container'>
                        <div className='home__info_container_price'>
                            {isCoinAvailableInSecretary
                                ? <img src={trophy} alt='trophy' />
                                : <img src={clock} alt='clock' />}
                            {isCoinAvailableInSecretary
                                ? <p className='home__info_container_price_text text text_center home__text_green'>{translation('Twoja nagroda jest gotowa do odbioru')}<br />{translation('i czeka na Ciebie w Sekretariacie!')}</p>
                                : <p className='home__info_container_price_text text text_center'>{translation('Udało Ci się zebrać wszystkie żetony.')}<br />{translation('Niedługo będzie można odebrać nagrodę.')}<br />{translation('Damy Ci znać.')}</p>}
                        </div>
                    </div>
                }

                {/* description/buttons show if/ifnot coinID be minted or conneted/notConnected */}
                <div className='home__info_container'>
                    <div className='home__info_text_container'>
                        {network
                            ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <button
                                    onClick={() => (+coidId === 0)
                                        ? document.getElementById("coins").scrollIntoView({ behavior: "smooth" })
                                        : setIsNftScreenVisible(true)}
                                    className={'btn_white_small'}
                                    style={(+coidId !== 0 && !isOwnerCoidId) ? { opacity: '0', pointerEvents: 'none' } : {}} // turn of if is not coin owner
                                >
                                    {(+coidId === 0)
                                        ? `${translation('Zebrane żetony')}(${visitedStations.filter(i => i === true).length}/${visitedStations.length})`
                                        : `${translation('Podgląd NFT')}`} {/* show if coinID will be minted  */}
                                </button>
                                {(+coidId === 0) && <button
                                    onClick={() => setSreenNumber(4)}
                                    className='btn_white_small home__button_skaner'>
                                    {translation('Skaner')}
                                </button>}
                            </div>
                            : <button
                                onClick={() => setSreenNumber(3)}
                                className='btn_white' >
                                {translation('Połącz aplikację z portfelem')}
                            </button>
                        }
                    </div>
                </div>
            </section>


            {/* --------------------- coins view ---------------------  */}
            {accounts.length && network && balance
                ? <section id='coins' className='screen'>
                    {visitedStationsWithSections.map(i => {
                        return (
                            <div key={i.name} className='home__stations_container'>
                                <button className='title home__stations_title'>
                                    {translation(i.name)}
                                    {/* <InfoIcon className='svg' /> */}
                                </button>
                                <div className='home__station_coins'>
                                    {i.stations.map(station => {
                                        return (
                                            <figure id={station.id} className='home__station_figure' key={station.id}>
                                                {station.isVisited ? i.coin[1] : i.coin[0]}
                                                {visitedStationsSpinner[station.id] && <Spinner />}
                                            </figure>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}

                </section>
                : <div></div>}

        </div >
    )
}

export default Home
