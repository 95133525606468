import React from 'react'

// translation
import { translation } from '../utils/translation'

const Popup = ({ setIsPopupVisible, title, text, component }) => {
    return (
        <div className='popup'>
            <div className='popup__container'>

                {!component ? <div>
                    <h3 className='title'>{translation(title)}</h3>
                    <p className='text margin1UpDown'>{translation(text)}</p>
                </div>
                    : component}

                <button className='btn_dark' onClick={() => setIsPopupVisible(false)}>{translation('OK')}</button>
            </div>

        </div>
    )
}

export default Popup
