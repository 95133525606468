import React from 'react'

// translation
import { translation } from '../utils/translation'


// svg
import { ReactComponent as CheckIcon } from '../assets/icons/check.svg'


const Wallet = ({ getUserAccounts, accounts, createNetwork, network, balance, addAICCoinsToMyAccount, setSreenNumber, coinSymbol }) => {

    return (
        <div className='screen'>

            <h3 className='title'>{translation('Status Twojego portfela')}</h3>

            {window.ethereum
                ? <div className='wallet__status_container'>
                    <div className='wallet__status_container_item' style={{ borderColor: `${accounts.length ? '#4FC86A' : '#D02951'}` }}>
                        <p className='text_small text_dark wallet__status_text'>{accounts.length ? translation('Aplikacja połączona z portfelem') : translation('Brak połączenia aplikacji z portfelem')}</p>
                        {!accounts.length
                            ? <button className='btn_bright_small wallet__status_btn' onClick={getUserAccounts}>{translation('Połącz')}</button>
                            : <CheckIcon style={{ fill: '#4FC86A' }} />}
                    </div>

                    <div className='wallet__status_container_item' style={{ borderColor: `${network ? '#4FC86A' : '#D02951'}` }}>
                        <p className='text_small text_dark wallet__status_text'>{network ? translation('Portfel połączony z siecią AIC') : translation('Brak połączenia portfela z siecią AIC')}</p>
                        {!network
                            ? <button disabled={accounts.length ? false : true} className='btn_bright_small wallet__status_btn' onClick={createNetwork}>{translation('Połącz')}</button>
                            : <CheckIcon style={{ fill: '#4FC86A' }} />}
                    </div>

                    <div className='wallet__status_container_item' style={{ borderColor: `${balance ? '#4FC86A' : '#D02951'}` }}>
                        <p className='text_small text_dark wallet__status_text'>{balance ? `${translation('Paliwo')}: ${(balance / 1000000000000000000).toFixed(8)} ${coinSymbol}` : translation('Brak paliwa')}</p>
                        {balance === 0
                            ? <button
                                disabled={(accounts.length && network) ? false : true}
                                className='btn_bright_small wallet__status_btn'
                                onClick={() => addAICCoinsToMyAccount()}>
                                {translation('Zatankuj')}
                            </button>
                            : <CheckIcon style={{ fill: '#4FC86A' }} />}
                    </div>
                </div>

                : <div className='wallet__status_container'>
                    <p className='text margin1UpDown'>{translation('Aby korzystać ze pełnej funkcjonalności AIC20 musisz stworzyć i ustawić portfel wirtualny MetaMask, a następnie połączyć go z siecią AIC i z AIC20.')}</p>
                    <button className='btn_white width100' onClick={() => setSreenNumber(0)}>{translation('Zobacz instrukcję konfiguracji')}</button>
                    <p className='text margin1UpDown'></p>
                </div>}


            <h3 className='title margin2Up'>{translation('Co to portfel MetaMask?')}</h3>
            <p className='text margin1UpDown'>{translation('MetaMask to jeden z dostępnych wirtualnych portfeli kryptowalutowych, pozwalających na bezpiecznie przechowywanie różnych aktywów cyfrowych w postaci różnego rodzaju cyfrowych tokenów (w tym kryptowalut). Współpracuje z sieciami blockchain opartymi o Ethereum Virtual Machine (taka sieć została uruchomiona w AIC).')}</p>

            <h3 className='title margin2Up'>{translation('Jak działa portfel?')}</h3>
            <p className='text margin1UpDown'>{translation('Portfel można porównać do sejfu, który posiada dwa klucze: klucz publiczny i prywatny. Kluczem publicznym można otworzyć sejf, żeby wsadzić do niego jakieś wartościowe rzeczy. Kiedy ktoś chce przekazać coś Tobie, używa klucza prywatnego i może bez Twojej zgody umieścić coś w Twoim sejfie. Z kolei klucz prywatny jest ściśle tajny i przeznaczony tylko dla właściciela. Tym kluczem może on otworzyć sejf i wyciągnąć z niego wartościowe przedmioty. W świecie cyfrowym kluczami są ciągi liczb i znaków generowane w oparciu o algorytmy kryptograficzne.')}</p>

            <h3 className='title margin2Up'>{translation('Czemu potrzebuję portfel?')}</h3>
            <p className='text margin1UpDown'>{translation('Aby przeprowadzić jakąkolwiek transakcję na sieci blockchain, potrzebny jest portfel w celach identyfikacji użytkownika. Informacja o każdym zebranym żetonie podczas naszej zabawy jest zapisywana w rejestrze blockchain, dlatego trzeba posiadać portfel, aby wziąć w niej udział.')}</p>

            <h3 className='title margin2Up'>{translation('Czy można zgubić portfel?')}</h3>
            <p className='text margin1UpDown'>{translation('Niestety portfel kryptograficzny można zgubić. Klucz prywatny jest trudnym do zapamiętania długim ciągiem liczb i cyfr. Jeśli pomylisz się przy zapisywaniu go lub gdzieś go zgubisz, nie będziesz w stanie dostać się do portfela i wykorzystać swoich dóbr. Aplikacje portfelowe wprowadziły pewne udogodnienie i zamiast skomplikowanego ciągu cyfr i znaków, wystarczy zapamiętać / zapisać kilkanaście słów w języku angielskim. Pilnuj ich jak oka w głowie.')}</p>

        </div>
    )
}

export default Wallet