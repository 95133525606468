import React, { useState } from 'react'

// spinner
import ClipLoader from "react-spinners/ClipLoader";

// translation
import { translation } from '../utils/translation'

// svg
import { ReactComponent as Close } from '../assets/icons/close.svg'

const NftScreen = ({ coidId, coinURL, setIsNftScreenVisible, setSreenNumber }) => {


    const [isSpinnerVisible, setIsSpinnerVisible] = useState(true)

    return (
        <div className='nftScreen'>

            <div className='nftScreen__container'>
                <button className='btn_clear nftScreen__container_btn_close' onClick={() => setIsNftScreenVisible(false)}><Close className='svg' /></button>

                <figure className='nftScreen__container_figure'>
                    <video
                        onPlay={() => setIsSpinnerVisible(false)}
                        onClick={e => e.target.paused ? e.target.play() : e.target.pause()}
                        controls={false}
                        autoPlay={true}
                        playsInline={true}
                        muted
                        className='nftScreen__container_img'
                        src={coinURL}
                        alt='mainCoin'
                        type="video/mp4"
                    />
                    {isSpinnerVisible && <div className='nftScreen_spinner'><ClipLoader color="white" loading={true} size={100} /></div>}
                </figure>

                <div>
                    <h3 className='title margin1UpDown text_white text_center text_thin text_very_big'>#{coidId}</h3>
                    <p className='text text_white text_center'>01AIC21</p>
                    <p className='text text_center text_small text_white'>20th anniversary AIC token</p>
                </div>

                <button
                    className='btn_clear nftScreen__container_btn_bottom margin1UpDown'
                    onClick={() => {
                        setSreenNumber(6)
                        setIsNftScreenVisible(false)
                    }}>
                    {translation('Dodaj NFT do MetaMask')}
                </button>
            </div>

        </div>
    )
}

export default NftScreen
