// config
export const BS_SCAN = 'https://bcscan.myaic.com/api?module=account&action=tokentx&address=' // TODO for prod: 'https://bcscan.myaic.com/api?module=account&action=tokentx&address='  for dev: 'https://bcscan-test.myaic.com/api?module=account&action=tokentx&address='
const mainURL = 'https://20.myaic.com' // TODO  for prod: 'https://20.myaic.com', for dev: 'https://test.20.myaic.com'
export const WEB_URL_NAME = mainURL
export const CONFIG_URL = mainURL + '/config.json'
export const CONFIG_CONST = 'config'
export const ABI_CONST = 'ABI'
export const RPC_URL_CONST = 'RPC_URL'
export const CHAIN_ID_CONST = 'CHAIN_ID'
export const CONTRACT_ADDRESS_CONST = 'CONTRACT_ADDRESS'
export const CONTRACT_URL_CONST = 'CONTRACT_URL'
export const NETWORK_NAME_CONST = 'NETWORK_NAME'
export const SYMBOL_CONST = 'SYMBOL'
export const BLOCK_EXPLORER_URL_CONST = 'BLOCK_EXPLORER_URL'
export const PICK_UP_YOUR_COIN_CONST = 'PICK_UP_YOUR_COIN'


// language
export const LANGUAGE_LOCAL_STOAGE = 'lang'
export const URL_TRANSLATION_PL = mainURL + '/translation_polish.json'
export const URL_TRANSLATION_UA = mainURL + '/translation_ukrainian.json'
export const URL_TRANSLATION_EN = mainURL + '/translation_english.json'
export const PL = 'PL'
export const UA = 'UA'
export const EN = 'EN'



export const HIDE_START_SCREEN_LOCAL_STOAGE = 'hideStartScreen'
