import React, { useState, useEffect, useRef } from 'react'
import QrReader from 'react-qr-reader'
import Switch from "react-switch";


// svg
import { ReactComponent as LockIcon } from '../assets/icons/lock.svg'

// translation
import { translation } from '../utils/translation'

const coinItemsArray = [
    { name: 'START', min: 0, max: 0 },
    { name: 'RMA', min: 1, max: 4 },
    { name: 'HISTORIA', min: 5, max: 7 },
    { name: 'TECH', min: 8, max: 12 },
    { name: 'PRODUKTY', min: 13, max: 16 },
    { name: 'LUDZIE', min: 17, max: 19 },
    { name: 'KONIEC', min: 20, max: 20 },
]

const Scan = ({ accounts, network, balance, setSreenNumber, visitStation, setErr }) => {

    // ref
    const qrReader = useRef(null)


    const [isNotRecognizedCodeQR, setIsNotRecognizedCodeQR] = useState(false)
    const [switchCamera, setSwitchCamera] = useState(localStorage.getItem('switchCamera') ? localStorage.getItem('switchCamera') : 'environment')
    const [legacyMode, setLegacyMode] = useState(localStorage.getItem('legacyMode') ? JSON.parse(localStorage.getItem('legacyMode')) : false) // scaner or photo

    // clear setIsNotRecognizedCodeQR
    useEffect(() => { setIsNotRecognizedCodeQR(false) }, [legacyMode])

    // set switchCamera to localStorage
    useEffect(() => { localStorage.setItem('switchCamera', switchCamera) }, [switchCamera])

    // set legacyMode to localStorage
    useEffect(() => { localStorage.setItem('legacyMode', legacyMode) }, [legacyMode])


    const handleScan = data => {
        console.log('data from qr reader: ', data)
        setErr(`[${Date.now()}] handleScan result: ${data}`)
        if (data) {
            setIsNotRecognizedCodeQR(false)
            visitStation(data) // call blockchain contract to visit station
        } else {
            legacyMode && setIsNotRecognizedCodeQR(true)
        }
    }
    const handleError = err => { setErr(`[${Date.now()}] Scan, handleError, err: ${err.message}`) }
    const openImageDialog = () => qrReader.current.openImageDialog()


    return (
        <div className='scan fullScreanSize'>


            {accounts.length && network && balance

                ? <div className='screen scan__scanner'>

                    <div className='scan__switch_container scan_hight_index'>
                        <p onClick={() => setLegacyMode(false)} className='switch_text' style={!legacyMode ? { color: 'white' } : { color: 'var(--colorBright)' }}>{translation('Skaner')}</p>
                        <Switch
                            onChange={() => setLegacyMode(prevState => !prevState)}
                            checked={legacyMode}
                            offColor='#000'
                            onColor='#373433'
                            handleDiameter={20} // bulb size
                            uncheckedIcon={false}
                            checkedIcon={false}
                        // height={28}
                        // width={56}
                        />
                        <p onClick={() => setLegacyMode(true)} className='switch_text' style={legacyMode ? { color: 'white' } : { color: 'var(--colorBright)' }}>{translation('Aparat')}</p>
                    </div>

                    <QrReader
                        ref={qrReader}
                        delay={1000} // 1s delay of next scan
                        onError={handleError}
                        onScan={handleScan}
                        facingMode={switchCamera}
                        legacyMode={legacyMode} // take photo from file if true or directly from camera if false
                        className='scan__qrReader'
                        showViewFinder={false}
                    />

                    <div className='scan__scaner_holder'></div>
                    <div className='scan__cover_sides'></div>
                    <div className='scan__cover_border'></div>

                    {legacyMode && isNotRecognizedCodeQR && <p className='scan__info_text text scan_hight_index'>{translation('Nie udało się rozpoznać kodu QR')}<br />{translation('Spróbuj ponownie')}</p>}
                    {!legacyMode && <button className='btn_bright margin1UpDown scan_hight_index' onClick={() => setSwitchCamera(prevState => prevState === 'environment' ? 'user' : 'environment')}>{translation('Zmień kamerę')}</button>}
                    {legacyMode && <button className='btn_bright margin1UpDown scan_hight_index' onClick={openImageDialog}>{translation('Zrób zdjęcie')}</button>}
                </div>

                : <div className='screen scan__disconnected'>
                    <div className='scan__icon_constainer'>
                        <LockIcon />
                    </div>
                    <p className='text scan__text_disconnnectTop margin2Up'>{translation('Połącz aplikację z portfelem, aby zbierać żetony')}</p>
                    <p className='text scan__text_disconnnectBottom margin1UpDown'>{translation('Dzięki temu nasza aplikacja odnotuje każdy zebrany żeton w Twoim wirtualnym portfelu.')}</p>
                    <button className='btn_white margin2Up' onClick={() => setSreenNumber(3)}>{translation('Połącz aplikację z portfelem')}</button>
                </div>
            }

        </div>
    )
}

export default Scan
