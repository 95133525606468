import React, { useState, useEffect } from 'react'

// translation
import { translation } from '../utils/translation'

// components
import MultipleSwitch from './MultipleSwitch'

//const
import { HIDE_START_SCREEN_LOCAL_STOAGE, PL, UA, EN } from '../utils/const'


// svg
import { ReactComponent as ArrowLeft } from '../assets/icons/chevron-left.svg'
import { ReactComponent as ArrowRight } from '../assets/icons/chevron-right.svg'

// img
import screen_1_bottom from '../assets/Start/screen_1_bottom.png'
import screen_1_top from '../assets/Start/screen_1_top.png'
import logo_1 from '../assets/Start/logo_1.png'
import years20 from '../assets/Start/20years.png'
import screen_2 from '../assets/Start/screen_2.png'
import screen_3 from '../assets/Start/screen_3.png'
import screen_4 from '../assets/Start/screen_4.png'
import screen_5 from '../assets/Start/screen_5.png'

const Start = ({ setSreenNumber }) => {

    const [screenNrVisible, setScreenNrVisible] = useState(1)
    const [color, setColor] = useState(['#D9E5EC', '#4F5F6E'])
    useEffect(() => {
        window.scrollTo({ top: 0 })

        switch (screenNrVisible) {
            case 1:
                setColor(['#D9E5EC', '#4F5F6E'])
                break;
            case 2:
                setColor(['#D9E5EC', '#4F5F6E'])
                break;
            case 3:
                setColor(['#3C737C', '#D9E5EC'])
                break;
            case 4:
                setColor(['#4F5F6E', '#D9E5EC'])
                break;
            case 5:
                setColor(['#747248', '#D9E5EC'])
                break;
            default:
                setColor(['#D9E5EC', '#4F5F6E'])
                break;
        }
    }, [screenNrVisible])


    const notShowStartScreenInNextOpen = screen => {
        localStorage.setItem(HIDE_START_SCREEN_LOCAL_STOAGE, true)
        setSreenNumber(screen)
    }

    return (

        <section className='start' style={{ background: `${color[0]}` }}>

            {/* DOTS ON TOP  */}
            <div className='start__dots_container'>
                {Array(5).fill('').map((i, index) => {
                    return (
                        <span
                            key={index}
                            style={screenNrVisible === (index + 1) ? { background: 'var(--colorDark)' } : { background: 'var(--colorBright)' }}
                            className='start__dot'>
                        </span>
                    )
                })}
            </div>

            {/* MIDDLE PART */}
            {screenNrVisible === 1 &&
                <div className='start__middle__content_container'>
                    <img className='img start__middle__content_img_top' src={screen_1_top} alt='screen_1_top' />
                    <img className='img start__middle__content_img_bottom' src={screen_1_bottom} alt='screen_1_bottom' />
                    <img className='img start__middle__content_img_middle' src={logo_1} alt='logo_1' />
                    <p className='margin1UpDown'></p>
                    <img className='img' src={years20} alt='years20' />
                </div>}

            {screenNrVisible === 2 &&
                <div className='start__middle__content_container'>
                    <img className='img' src={screen_2} alt='screen_2' />
                    <h3 style={{ color: `${color[1]}` }} className='title start__title'>{translation('Szukaj ukrytych kodów')}</h3>
                    <p style={{ color: `${color[1]}` }} className='text text_center margin1UpDown'>{translation('Na wystawie znajdziesz kody QR.')}<br />{translation('Skanuj je w naszej aplikacji.')}</p>
                </div>}

            {screenNrVisible === 3 &&
                <div className='start__middle__content_container'>
                    <img className='img' src={screen_3} alt='screen_3' />
                    <h3 style={{ color: `${color[1]}` }} className='title start__title'>{translation('Zbieraj żetony')}</h3>
                    <p style={{ color: `${color[1]}` }} className='text text_center margin1UpDown'>{translation('Każdy zeskanowany kod')}<br />{translation('to wirtualny żeton.')}</p>
                </div>}

            {screenNrVisible === 4 &&
                <div className='start__middle__content_container'>
                    <img className='img' src={screen_4} alt='screen_4' />
                    <h3 style={{ color: `${color[1]}` }} className='title start__title'>{translation('Odbierz nagrodę')}</h3>
                    <p style={{ color: `${color[1]}` }} className='text text_center margin1UpDown'>{translation('Uzbieraj pełną kolekcję żetonów')}<br />{translation('i odbierz  nagrodę!')}</p>
                </div>}

            {screenNrVisible === 5 &&
                <div className='start__middle__content_container'>
                    <img className='img' src={screen_5} alt='screen_5' />
                    <h3 style={{ color: `${color[1]}` }} className='title start__title'>{translation('Wirtualny portfel')}</h3>
                    <p style={{ color: `${color[1]}` }} className='text text_center margin1UpDown'>{translation('Żeby zbierać żetony, potrzebny')}<br />{translation('jest wirtualny portfel.')}</p>
                    <button onClick={() => notShowStartScreenInNextOpen(0)} className='btn_bright' >{translation('Stwórz portfel krok po kroku')}</button>
                </div>}





            {/* NAV BOTTOM  */}
            <nav className='start__navigationBottom'>
                <div className='start__navigationBottom_container'>
                    {screenNrVisible !== 1
                        ? <button
                            onClick={() => setScreenNrVisible(prevState => prevState - 1)}
                            style={screenNrVisible === 2 ? { color: 'var(--backgroundStartFirstScreen)' } : { color: 'var(--colorBrightWhite)' }}
                            className='btn_clear' >
                            <ArrowLeft className='svg' style={screenNrVisible === 2 ? { fill: 'var(--backgroundStartFirstScreen)' } : { fill: 'var(--colorBrightWhite)' }} />
                            {translation('Wstecz')}
                        </button>
                        : <MultipleSwitch
                            buttonList={[PL, UA, EN]}
                            colorOn='black'
                            colorOf='var(--colorBright)'
                            backgroundOn='white'
                            backgroundOf='transparent'
                            isWindowReload={true} />}
                    {screenNrVisible !== 5
                        ? <button
                            style={screenNrVisible === 2 ? { color: 'var(--backgroundStartFirstScreen)' } : { color: 'var(--colorBrightWhite)' }}
                            onClick={() => setScreenNrVisible(prevState => prevState + 1)}
                            className='btn_clear' >
                            {translation('Dalej')}
                            <ArrowRight className='svg' style={screenNrVisible === 2 ? { fill: 'var(--backgroundStartFirstScreen)' } : { fill: 'var(--colorBrightWhite)' }} />
                        </button>
                        : <button
                            onClick={() => notShowStartScreenInNextOpen(1)}
                            className='btn_clear'
                            style={{ color: 'var(--colorBrightWhite)', margin: '0 1.5rem 0 0' }}>
                            {translation('Pomiń')}
                        </button>}
                </div>
            </nav>

        </section>

    )
}

export default Start
