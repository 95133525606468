import React, { useState } from 'react'
import { MapInteractionCSS } from 'react-map-interaction';


// translation
import { translation } from '../utils/translation'


// components
import MultipleSwitch from './MultipleSwitch'

//const
import { PL, UA, EN } from '../utils/const'

// img
import helpMap from '../assets/Help/helpMap.png'
import { ReactComponent as Phone } from '../assets/icons/phone.svg'
import { ReactComponent as Mail } from '../assets/icons/mail.svg'

//svg
import { ReactComponent as Close } from '../assets/icons/close.svg'
import { ReactComponent as Resize } from '../assets/icons/external-link.svg'
import { ReactComponent as Maximize } from '../assets/icons/maximize.svg'


const Help = () => {

    const [isMapFullView, setIsMapFullView] = useState(false)

    return (
        <section className='screen'>
            <div className='help__switch'>
                <p className='text margin1UpDown'>{translation('Język aplikacji')}</p>
                <MultipleSwitch
                    buttonList={[PL, UA, EN]}
                    colorOn='white'
                    colorOf='#818B92'
                    backgroundOn='var(--colorDark)'
                    backgroundOf='transparent'
                    isWindowReload={true} />
            </div>

            <div className='margin2Up'>
                <h3 className='title'>{translation('Mapa wystawy')}</h3>
                <p className='text margin1UpDown'></p>

                <div className={`help__img_container ${isMapFullView && 'help__img_container_full_screen'}`}>
                    <MapInteractionCSS disableZoom={false} minScale={1} maxScale={5}>
                        <img className='help__img img' src={helpMap} alt='helpMap' />
                    </MapInteractionCSS>
                    {isMapFullView && <Close onClick={() => setIsMapFullView(prevState => !prevState)} className='svg help__svg_map' />}
                    {!isMapFullView && <Maximize onClick={() => setIsMapFullView(prevState => !prevState)} className='svg help__svg_map' />}
                </div>
            </div>

            <div className='margin2Up'>
                <h3 className='title'>{translation('Potrzebujesz pomocy?')}</h3>
                <p className='text margin1UpDown'>{translation('Skontaktuj się z nami, jeśli masz jakiś problem z aplikacją AIC20.')}</p>
                <a className='help__link' href='mailto:aic20@myaic.com'><Mail className='help__svg' />aic20@myaic.com</a>
                <a className='help__link' href="tel:795-454-831"><Phone className='help__svg' />795-454-831</a>
                <p className='text margin2Up'>{translation('Ikony użyte w aplikacji pochodzą ze zbioru')} <a href='https://tabler-icons.io/' target='_blank'>tabler icons</a> {translation('i są udostępnione na licencji MIT.')}</p>
            </div>

        </section >
    )
}

export default Help
