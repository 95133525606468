import React from 'react'

// translation
import { translation } from '../utils/translation'

//const
import { LANGUAGE_LOCAL_STOAGE, PL, UA, EN, } from '../utils/const'


import { copyToClipboard } from '../utils/copyToClipboard'

//img
import addNFT from '../assets/AddNftTokenInstruction/addNFT.png'

import step_A_PL from '../assets/AddNftTokenInstruction/step_A.png'
import step_B_PL from '../assets/AddNftTokenInstruction/step_B.png'
import step_C_PL from '../assets/AddNftTokenInstruction/step_C.png'
import step_D_PL from '../assets/AddNftTokenInstruction/step_D.png'

import step_A_UA from '../assets/AddNftTokenInstruction/step_A_UA.png'
import step_B_UA from '../assets/AddNftTokenInstruction/step_B_UA.png'
import step_C_UA from '../assets/AddNftTokenInstruction/step_C_UA.png'
import step_D_UA from '../assets/AddNftTokenInstruction/step_D_UA.png'

import step_A_EN from '../assets/AddNftTokenInstruction/step_A_EN.png'
import step_B_EN from '../assets/AddNftTokenInstruction/step_B_EN.png'
import step_C_EN from '../assets/AddNftTokenInstruction/step_C_EN.png'
import step_D_EN from '../assets/AddNftTokenInstruction/step_D_EN.png'






const AddNftTokenInstruction = ({ coidId, contractAddress }) => {

    const language = localStorage.getItem(LANGUAGE_LOCAL_STOAGE) ?? PL

    return (
        <section className='addNftTokenInstruction'>


            <div className='config__middle__header'>
                <p className='config__middle__header_top_text'>{translation('Instrukcja')}</p>
                <p className='config__middle__header_bottom_text'>{translation('Dodanie NFT 01AIC21 do portfela MetaMask')}</p>
            </div>

            <div className='config__middle__content_container'>
                <p className='text margin1UpDown'>{translation('Gratulujemy jeszcze raz ukończenia naszej zabawy!')}</p>
                <figure className='addNftTokenInstruction__figure_small'>
                    <img style={{ maxWidth: '150px' }} className='img' src={addNFT} alt='mainCoin' />
                </figure>
                <p className='text margin1UpDown'>{translation('Cyfrowa moneta NFT o unikalnym numerze')} {coidId} {translation('z jubileuszowej kolekcji 01AIC21 jest Twoja.')}</p>
                <p className='text margin1UpDown'>{translation('Informacja o tym została zapisana w  specjalnym rejestrze zbudowanym na technologii blockchain. Dodaj adres kolekcji do wirtualnego portfela zgodnie z poniższą instrukcją, by zobaczyć tam swoje NFT.')}</p>

                <h3 className='title margin2Up'>{translation('Jak dodać NFT do portfela')}</h3>
                <div className='config__middle__content_container_web'>
                    <p className='config__middle__content_container_web_point text margin1UpDown'>{translation('A.')}</p>
                    <p className='text margin1UpDown'>{translation('Otwórz aplikację MetaMask i na ekranie głównym kliknij na zakładkę')} <span className='config__middle_textGreen'>NFTs</span>{translation(', aby do niej przejść.')}</p>
                </div>
                {language === PL && <img className='img' src={step_A_PL} alt='step_A' />}
                {language === UA && <img className='img' src={step_A_UA} alt='step_A' />}
                {language === EN && <img className='img' src={step_A_EN} alt='step_A' />}
                <div className='config__middle__content_container_web'>
                    <p className='config__middle__content_container_web_point text margin1UpDown'>{translation('B.')}</p>
                    <p className='text margin1UpDown'>{translation('W tej zakładce zobaczysz swoje NFT. Na razie jest tu pusto, ale jeszcze chwila! Teraz kliknij na przycisk')} <span className='config__middle_textGreen'>Import NFTs</span> {translation('znajdujący się w dolnej części ekranu.')}</p>
                </div>
                {language === PL && <img className='img' src={step_B_PL} alt='step_B' />}
                {language === UA && <img className='img' src={step_B_UA} alt='step_B' />}
                {language === EN && <img className='img' src={step_B_EN} alt='step_B' />}
                <div className='config__middle__content_container_web'>
                    <p className='config__middle__content_container_web_point text margin1UpDown'>{translation('C.')}</p>
                    <p className='text margin1UpDown'>{translation('Skopiuj poniższe dane do odpowiednich rubryk. Na koniec zatwierdź operację klikając')} <span className='config__middle_textGreen'>Import</span>.</p>
                </div>
                {language === PL && <img className='img' src={step_C_PL} alt='step_C' />}
                {language === UA && <img className='img' src={step_C_UA} alt='step_C' />}
                {language === EN && <img className='img' src={step_C_EN} alt='step_C' />}
                <p className='margin1UpDown'></p>
                <p className='text'>Address</p>
                <div className='text  config__middle_hint config__middle__content_container_web_displayFlex '>
                    <p className='text_overflow_hiden'>{contractAddress ?? ''}</p>
                    <button
                        className='btn_bright_small2'
                        onClick={() => copyToClipboard(contractAddress ?? '')}>
                        {translation('Kopiuj')}
                    </button>
                </div>
                <p className='margin1UpDown'></p>
                <p className='text'>ID</p>
                <div className='text config__middle_hint config__middle__content_container_web_displayFlex'>
                    <p>{coidId}</p>
                    <button
                        className='btn_bright_small2'
                        onClick={() => copyToClipboard(coidId)}>
                        {translation('Kopiuj')}
                    </button>
                </div>
                <p className='margin1UpDown'></p>

                <div className='config__middle__content_container_web'>
                    <p className='config__middle__content_container_web_point text margin1UpDown'>{translation('D.')}</p>
                    <p className='text margin1UpDown'>{translation('Brawo! Masz już w portfelu swój NFT. Możesz teraz w niego kliknąć, aby zobaczyć go w pełnej okazałości.')}</p>
                </div>
                {language === PL && <img className='img' src={step_D_PL} alt='step_D' />}
                {language === UA && <img className='img' src={step_D_UA} alt='step_D' />}
                {language === EN && <img className='img' src={step_D_EN} alt='step_D' />}
            </div>
        </section >
    )
}

export default AddNftTokenInstruction
