import React, { useState, useEffect } from 'react'

// const
import { LANGUAGE_LOCAL_STOAGE } from '../utils/const'



const MultipleSwitch = ({ buttonList, colorOn, colorOf, backgroundOn, backgroundOf, isWindowReload }) => {

    //create default buttons list => all are Of
    const defaultList = () => buttonList.map(i => ({ name: i, color: `${colorOf}`, background: `${backgroundOf}` }))

    const [switchButtons, setswitchButtons] = useState(defaultList)

    //set fbutton as ON
    useEffect(() => {
        if (localStorage.getItem(LANGUAGE_LOCAL_STOAGE)) {
            buttonNameToOn(localStorage.getItem(LANGUAGE_LOCAL_STOAGE))
        } else {
            localStorage.setItem(LANGUAGE_LOCAL_STOAGE, buttonList[0])
            buttonNameToOn(buttonList[0])
        }
    }, [])

    // onClick handler
    const onClickHandler = button => {
        console.log('button: ', button)
        buttonNameToOn(button.name)
        localStorage.setItem(LANGUAGE_LOCAL_STOAGE, button.name)
        isWindowReload && window.location.reload()
    }

    // adjust button as ON
    const buttonNameToOn = buttonName => {
        const buttonsListAfterChange = defaultList().map(i => i.name === buttonName ? ({ name: buttonName, color: `${colorOn}`, background: `${backgroundOn}` }) : i)
        setswitchButtons(buttonsListAfterChange)
    }


    return (
        <div style={{
            background: 'rgba(255,255,255,0.75)',
            borderRadius: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.2rem',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
        }}>
            {switchButtons.map(i => {
                return (
                    <div key={i.name}>
                        <button
                            style={{
                                width: '2rem',
                                height: '2rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: `${i.background}`,
                                color: `${i.color}`, borderRadius: '0.5rem',
                                outline: 'none', border: 'none',
                                padding: '0.2rem 0.5rem', margin: '0.2rem',
                                fontSize: '1rem'
                            }}
                            onClick={() => onClickHandler(i)}>
                            {i.name}
                        </button>
                    </div>
                )
            })}
        </div>
    )
}

export default MultipleSwitch
