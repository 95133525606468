import React, { useState, useEffect } from 'react'
import axios from 'axios'

//const
import {
  BS_SCAN,
  CONFIG_URL,
  CONFIG_CONST,
  ABI_CONST,
  RPC_URL_CONST,
  CHAIN_ID_CONST,
  CONTRACT_ADDRESS_CONST,
  CONTRACT_URL_CONST,
  NETWORK_NAME_CONST,
  SYMBOL_CONST,
  BLOCK_EXPLORER_URL_CONST,
  PICK_UP_YOUR_COIN_CONST,
  LANGUAGE_LOCAL_STOAGE,
  URL_TRANSLATION_PL,
  URL_TRANSLATION_UA,
  URL_TRANSLATION_EN,
  PL,
  UA,
  EN,
} from '../utils/const'


// components
import MultipleSwitch from '../components/MultipleSwitch'

// screens
import Popup from '../components/Popup'
import NftScreen from '../components/NftScreen'
import AddNftTokenInstruction from '../components/AddNftTokenInstruction'
import Nav from '../components/Nav'
import Start from '../components/Start'
import Config from '../components/Config'
import Home from '../components/Home'
import Nft from '../components/Nft'
import Scan from '../components/Scan'
import Wallet from '../components/Wallet'
import Help from '../components/Help'


// icons coins
import { ReactComponent as StartIcon } from '../assets/coins/start.svg'
import { ReactComponent as StartIcon_filled } from '../assets/coins/start_filled.svg'
import { ReactComponent as Produkty } from '../assets/coins/produkty.svg'
import { ReactComponent as Produkty_filled } from '../assets/coins/produkty_filled.svg'
import { ReactComponent as Rurki } from '../assets/coins/rurki.svg'
import { ReactComponent as Rurki_filled } from '../assets/coins/rurki_filled.svg'
import { ReactComponent as Technologia } from '../assets/coins/technologia.svg'
import { ReactComponent as Technologia_filled } from '../assets/coins/technologia_filled.svg'
import { ReactComponent as Rma } from '../assets/coins/rma.svg'
import { ReactComponent as Rma_filled } from '../assets/coins/rma_filled.svg'
import { ReactComponent as Wliczbach } from '../assets/coins/wliczbach.svg'
import { ReactComponent as Wliczbach_filled } from '../assets/coins/wliczbach_filled.svg'
import { ReactComponent as Ludzie } from '../assets/coins/ludzie.svg'
import { ReactComponent as Ludzie_filled } from '../assets/coins/ludzie_filled.svg'
import { ReactComponent as Historia } from '../assets/coins/historia.svg'
import { ReactComponent as Historia_filled } from '../assets/coins/historia_filled.svg'

// web3
import Web3 from 'web3'

// change array from getVisits(adres_portfela) to sections with name, id, stationNumber, value
const changeVisitedStationsToSections = stationsArray => {
  const stationsArrayWithStationNumbers = stationsArray.map((i, index) => ({ id: index, stationNumber: index + 1, isVisited: i }))
  return [
    { name: 'Początek', coin: [<StartIcon />, <StartIcon_filled />,], stations: stationsArrayWithStationNumbers.filter((j, index) => j.id >= 0 && j.id <= 0) },
    { name: 'Produkty', coin: [<Produkty />, <Produkty_filled />,], stations: stationsArrayWithStationNumbers.filter((j, index) => j.id >= 1 && j.id <= 2) },
    { name: 'Rurki', coin: [<Rurki />, <Rurki_filled />,], stations: stationsArrayWithStationNumbers.filter((j, index) => j.id >= 3 && j.id <= 4) },
    { name: 'Technologia', coin: [<Technologia />, <Technologia_filled />,], stations: stationsArrayWithStationNumbers.filter((j, index) => j.id >= 5 && j.id <= 7) },
    { name: 'RMA', coin: [<Rma />, <Rma_filled />,], stations: stationsArrayWithStationNumbers.filter((j, index) => j.id >= 8 && j.id <= 10) },
    { name: 'W liczbach', coin: [<Wliczbach />, <Wliczbach_filled />,], stations: stationsArrayWithStationNumbers.filter((j, index) => j.id >= 11 && j.id <= 12) },
    { name: 'Ludzie', coin: [<Ludzie />, <Ludzie_filled />,], stations: stationsArrayWithStationNumbers.filter((j, index) => j.id >= 13 && j.id <= 13) },
    { name: 'Historia', coin: [<Historia />, <Historia_filled />,], stations: stationsArrayWithStationNumbers.filter((j, index) => j.id >= 14 && j.id <= 19) },
  ]
}


const App = () => {

  const [err, setErr] = useState('no error')
  const [errCountToShowError, setErrCountToShowError] = useState(0) // error show when >= 10
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [isNftScreenVisible, setIsNftScreenVisible] = useState(false)
  const [configJson, setConfigJson] = useState(JSON.parse(localStorage.getItem(CONFIG_CONST)) ?? {})
  const [screenNumber, setSreenNumber] = useState(1)
  const [accounts, setAccounts] = useState([]) // array of account => take accounts[0]
  const [network, setNetwork] = useState('') // CHAIN_ID_HEX
  const [visitedStations, setVisitedStations] = useState(Array(20).fill(false)) // visitedStations => array of bools => 20 pcs
  const [visitedStationsWithSections, setVisitedStationsWithSections] = useState(changeVisitedStationsToSections(visitedStations)) // visitedStations after convert
  const [visitedStationsSpinner, setVisitedStationsSpinner] = useState(Array(20).fill(false)) // visitedStationsSpinner => array of bools => 20 items
  const [coidIdList, setCoidIdList] = useState([])
  const [coidId, setCoidId] = useState(0) // current coin ID taken from coidIdList
  const [coinURL, setCoinUrl] = useState('') // current coin URL taken from coidIdList
  const [isOwnerCoidId, setIsOwnerCoidId] = useState(false) // current coin isOwner taken from coidIdList
  const [balance, setBalance] = useState(0)

  const CHAIN_ID_HEX = '0x' + Number(configJson[CHAIN_ID_CONST])?.toString(16) // 0x-prefixed hexadecimal string


  // initial screen adjust
  useEffect(() => {
    const screenNumberSavedInLocalStorage = localStorage.getItem('screenNumber')
    if (screenNumberSavedInLocalStorage) {
      setSreenNumber(+localStorage.getItem('screenNumber'))
    } else {
      !window.ethereum && setSreenNumber(-1)
      window.ethereum && setSreenNumber(3)
    }
  }, [])

  // scroll to top when change screen
  useEffect(() => {
    window.scrollTo({ top: 0 })
    localStorage.setItem('screenNumber', screenNumber)
  }, [screenNumber])


  // auto convert from visitedStations to setVisitedStationsWithSections
  useEffect(() => { setVisitedStationsWithSections(changeVisitedStationsToSections(visitedStations)) }, [visitedStations])


  // auto connect wallet and network if is access
  useEffect(() => {
    (async () => {
      const web3 = new Web3(window.ethereum)
      const accounts = await web3.eth.getAccounts()
      const chainId = await web3.eth.getChainId()
      setErr(`accounts: ${accounts}, chainId: ${chainId}, `)
      // after switch network iPhone dont' see network (chainId === null) and need reload
      if (localStorage.getItem('reload1')) {
        localStorage.removeItem('reload1')
        window.location.reload()
      }
      if (accounts.length > 0) { getUserAccounts() }
      if (configJson[CHAIN_ID_CONST] === chainId && accounts.length > 0) { createNetwork() }
    })()


  }, [])

  // auto show languge chose screen if is not set
  useEffect(() => {
    const component = () => {
      return (
        <div>
          <div className='help__switch'>
            <p className='text margin1UpDown'>Język aplikacji</p>
            <MultipleSwitch
              buttonList={[PL, UA, EN]}
              colorOn='white' c
              olorOf='#818B92'
              backgroundOn='var(--colorDark)'
              backgroundOf='transparent'
              isWindowReload={false} />
          </div>
          <p className='margin1UpDown'></p>
        </div>
      )
    }
    window.ethereum && !localStorage.getItem(LANGUAGE_LOCAL_STOAGE) && setIsPopupVisible({ title: '', text: '', component: component() })
  }, [])


  // account change listener
  useEffect(() => { if (window.ethereum) { window.ethereum.on('accountsChanged', setAccounts) } }, [])


  // fetch config when app start
  useEffect(() => {
    (async () => {
      try {
        // query URL without using browser cache
        const config = {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        }
        const resp = await axios.get(CONFIG_URL, config)
        const respConfigJson = resp.data
        const respContractABI = await axios.get(respConfigJson[CONTRACT_URL_CONST], config)
        const compliteConfigJson = { ...respConfigJson, [ABI_CONST]: respContractABI.data.abi }
        setConfigJson(compliteConfigJson)
        localStorage.setItem(CONFIG_CONST, JSON.stringify(compliteConfigJson))
      } catch (err) {
        console.log(`fetch Config, err: ${err}`)
        setErr(`fetch Config, err: ${err}`)
      }
    })()
  }, [])


  // fetch translations json when app start
  useEffect(() => {
    fetchTranslations(URL_TRANSLATION_PL, PL)
    fetchTranslations(URL_TRANSLATION_UA, UA)
    fetchTranslations(URL_TRANSLATION_EN, EN)
  }, [])


  // fetch translations json when app start
  const fetchTranslations = (url, lang) => {
    const config = {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
    }
    axios.get(url, config)
      .then(resp => {
        localStorage.setItem(lang, JSON.stringify(resp.data))
      })
      .catch(err => {
        console.log(`fetchTranslations ${lang}, err: ${err}`)
        setErr(`getUserAccounts ${lang}, err: ${err}`)
      })
  }


  //get user accounts => connect MetaMask
  const getUserAccounts = async () => {

    // after switch network iPhone dont' see network (chainId === null) and need reload
    if (localStorage.getItem('reload2')) {
      localStorage.removeItem('reload2')
      window.location.reload()
    }

    try {
      const accounts = await window.ethereum.send('eth_requestAccounts')
      setAccounts(accounts.result)
    } catch (err) {
      console.log(`getUserAccounts, err: ${err}`)
      setErr(`getUserAccounts, err: ${err}`)
    }
  }

  // create new AIC netowrk in metaMask and switch to AIC network
  const createNetwork = async () => {

    // create Web3 object if null
    window.web3 = new Web3(window.ethereum)

    //get chainID (web3.eth method)
    let chainId = await window.web3.eth.getChainId()
    setErr(`chainId: ${chainId}`)

    // if network exist
    if (configJson[CHAIN_ID_CONST] === chainId) {
      try {
        await window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: CHAIN_ID_HEX }] })
        setNetwork(configJson[NETWORK_NAME_CONST])
      } catch (switchError) {
        console.log(`createNetwork, switchError: ${err}`)
        setErr(`createNetwork, switchError: ${err}`)
      }
      return
    }

    // create new network
    localStorage.setItem('reload1', true) // reload view after change network to remove errors
    localStorage.setItem('reload2', true) // reload view after change network to remove errors
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: CHAIN_ID_HEX, // 0x-prefixed hexadecimal string
          blockExplorerUrls: [configJson[BLOCK_EXPLORER_URL_CONST]], // string[]
          chainName: configJson[NETWORK_NAME_CONST], // string
          nativeCurrency: {
            name: configJson[SYMBOL_CONST], // string
            symbol: configJson[SYMBOL_CONST], // string
            decimals: 18, // number
          },
          rpcUrls: [configJson[RPC_URL_CONST]], // string[]
        }],
      })
      setNetwork(configJson[NETWORK_NAME_CONST])
    } catch (addError) {
      console.log(`createNetwork, addError: ${err}`)
      setErr(`createNetwork, addError: ${addError}`)
    }
  }


  // get data from BC
  useEffect(() => {
    network && getDataFromBC()
  }, [network, accounts[0]])


  // get data from BC
  const getDataFromBC = async () => {

    // create Web3 object and add to window
    window.web3 = new Web3(window.ethereum);

    //get ballance (web3.eth method)
    let balance = await window.web3.eth.getBalance(accounts[0])
    const weiBalance = window.web3.utils.fromWei(balance, 'ether')
    setBalance(+balance)

    // get gas price
    let gasPrice = await window.web3.eth.getGasPrice()

    // CONTRACT only to call (without options)
    try {
      let contractInstance = new window.web3.eth.Contract(configJson[ABI_CONST], configJson[CONTRACT_ADDRESS_CONST])

      // get visited stations (array of bool)  and set to state
      const visitedStationsAfterMint = await contractInstance.methods.getVisits(accounts[0]).call()
      const visitedStationsAfterMintWithoutLastItem = visitedStationsAfterMint.map(i => i) // rewrite array to modify after
      visitedStationsAfterMintWithoutLastItem.pop() // only 20 items (21 - 1)
      setVisitedStations(visitedStationsAfterMintWithoutLastItem)
      setErr(`${visitedStationsAfterMintWithoutLastItem}`)

      // get all coins which are or was in user account
      const urlForCoins = BS_SCAN + accounts[0]
      const respCoin = await axios.get(urlForCoins)
      console.log('respCoin: ', respCoin.data.result)
      let coinsList = respCoin.data.result.filter(i => i.contractAddress.toUpperCase() === configJson[CONTRACT_ADDRESS_CONST].toUpperCase()) // get only current contracts transactions
      coinsList = coinsList.map(i => i.tokenID) // get only tokens ID and put to list
      if (coinsList.length) {
        const coinsListUnique = new Set(coinsList)
        coinsList = Array.from(coinsListUnique)
      }
      console.log('coinsList: ', coinsList)
      setCoidIdList(coinsList)

      //  get minted Coin Id data
      addCoinDataFromContract(coinsList[0])

    } catch (err) {
      console.log(`getDataFromBC, err: ${err.message}`)
      setErr(`getDataFromBC, err: ${err.message}`)
    }
  }

  const addCoinDataFromContract = async (coidId) => {

    // reset to default values
    setCoinUrl('')
    setIsOwnerCoidId(false)

    // stop if coinId 0 or undefined
    if (!coidId) {
      setCoidId(0) // make default value if coinID is undefined
      return
    } else {
      setCoidId(coidId) // set new coinId in the case to try throw some error
    }

    try {
      const contractInstance = new window.web3.eth.Contract(configJson[ABI_CONST], configJson[CONTRACT_ADDRESS_CONST])
      const coinURL = await contractInstance.methods.tokenURI(coidId).call()
      const resp = await axios.get(coinURL) //  get minted Coin URL => data from contract
      const ownerCoinAddress = await contractInstance.methods.ownerOf(coidId).call()

      console.log('ownerCoinAddress: ', ownerCoinAddress)
      console.log('isOwner: ', accounts[0].toUpperCase() === ownerCoinAddress.toUpperCase())

      setCoidId(coidId)
      setCoinUrl(resp.data.animation)
      setIsOwnerCoidId(accounts[0].toUpperCase() === ownerCoinAddress.toUpperCase())
    } catch (err) {
      console.log(`addCoinDataFromContract, err: ${err.message}`)
      setErr(`addCoinDataFromContract, err: ${err.message}`)
    }
  }

  // ad balance
  const addAICCoinsToMyAccount = async () => {

    // set fake number of WAT on begining to not wait for true fetch
    setBalance(1000000000000000000)

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 'Hc7w56bF0T4dvRAyAh4RC6GcYz2aHcpy9LnmLv7e'
        }
      }
      const response = await axios.post('https://faucet.bc.myaic.com', { address: accounts[0] }, config)
    } catch (err) {
      console.log('addAICCoinsToMyAccount, err: ', err)
      setErr(`addAICCoinsToMyAccount, err: ${err}`)
    }
  }

  // mint 
  const visitStation = async (stationHash) => { // kody z stationsCode

    // open Home screen
    setSreenNumber(1)

    // get stationNumber
    let stationNumber = 0
    try {
      let contractInstance = new window.web3.eth.Contract(configJson[ABI_CONST], configJson[CONTRACT_ADDRESS_CONST]) // contract instance only for call stationNumber
      const stationNumberFromContract = await contractInstance.methods.stationNumber(stationHash).call() // return 1-21 stations, if 0 then not station code
      stationNumber = stationNumberFromContract - 1
      if (stationNumber === -1) {
        console.log('Not station hash')
        setErr('Not station hash')
        setIsPopupVisible({ title: 'Ups! Błędny kod QR', text: 'Prawdopodobnie skanujesz kod, którego nie ma w naszej zabawie. Spróbuj zeskanować inny kod.' })
        return
      }
    } catch (err) {
      console.log(`visitStation err1: ${err.message}`)
      setErr(`visitStation err1: ${err.message}`)
      return
    }

    // if coin is already minted set popup and return
    if (visitedStations[stationNumber] === true) {
      setIsPopupVisible({ title: 'Ups! Masz już ten żeton', text: 'Szukaj kolejnych kodów, aby zebrać pozostałe żetony.' })
      return
    }

    // set spinner
    setVisitedStationsSpinner(prevState => {
      let spinersArray = [...prevState]
      spinersArray[stationNumber] = true
      return spinersArray
    })

    // get transaction count to contractOptions
    let getTransactionCount = await window.web3.eth.getTransactionCount(accounts[0])

    // contrac options
    const contractOptions = { // contract work without options
      from: accounts[0], // default from address
      to: configJson[CONTRACT_ADDRESS_CONST], // contract adress
      nonce: getTransactionCount, // number of transactions made by user before
      gasPrice: '1000000', // default gas price in wei, 20 gwei is '20000000000' (było dobrze przy '1000000')
      gas: 1000000, // gas limit, (last gasUsed: 29929), min is 22616 => 30000 is OK (było dobrze przy 1000000)
    }

    // go to coin Viev if is spiner ON
    const mintedCoinElement = document.getElementById(stationNumber)
    mintedCoinElement?.scrollIntoView({ behavior: "smooth" })

    try {
      const contractInstance = new window.web3.eth.Contract(configJson[ABI_CONST], configJson[CONTRACT_ADDRESS_CONST], contractOptions)
      const mintNFT = await contractInstance.methods.mintNFT(stationHash).send({ from: accounts[0] })
      console.log('mintNFT hash:', mintNFT)
      setErr(`mintNFT hash`)

    } catch (err) {
      console.log(`visitStation err2: ${err.message}`)
      setErr(`visitStation err2: ${err.message}`)

      // turn of spinner
      setVisitedStationsSpinner(prevState => {
        let spinersArray = [...prevState]
        spinersArray[stationNumber] = false
        return spinersArray
      })
    }

    // wait and ...
    setTimeout(() => {

      //ask for minted stations
      getDataFromBC()

      // turn of spinner
      setVisitedStationsSpinner(prevState => {
        let spinersArray = [...prevState]
        spinersArray[stationNumber] = false
        return spinersArray
      })

    }, 5000)
  }


  return (
    <main className='app'>

      {/* errors show after click 10 times */}
      {(errCountToShowError >= 10) && <p className='error_text'>{err}</p>}
      <button className='error_btn' onClick={() => setErrCountToShowError(prevState => prevState + 1)}>showHidden</button>

      {isPopupVisible && <Popup
        setIsPopupVisible={setIsPopupVisible}
        title={isPopupVisible.title}
        text={isPopupVisible.text}
        component={isPopupVisible.component}
      />}

      {isNftScreenVisible && <NftScreen
        coidId={coidId}
        coinURL={coinURL}
        setIsNftScreenVisible={setIsNftScreenVisible}
        setSreenNumber={setSreenNumber}
      />}

      {screenNumber === -1 && <Start
        setSreenNumber={setSreenNumber}
      />}

      {screenNumber === 0 && <Config
        setSreenNumber={setSreenNumber}
      />}

      {screenNumber === 1 && <Home
        visitedStations={visitedStations}
        visitedStationsWithSections={visitedStationsWithSections}
        visitedStationsSpinner={visitedStationsSpinner}
        accounts={accounts}
        network={network}
        balance={balance}
        setSreenNumber={setSreenNumber}
        coidIdList={coidIdList}
        coidId={coidId}
        isOwnerCoidId={isOwnerCoidId}
        addCoinDataFromContract={addCoinDataFromContract}
        setIsNftScreenVisible={setIsNftScreenVisible}
        isCoinAvailableInSecretary={configJson[PICK_UP_YOUR_COIN_CONST]}
      />}

      {screenNumber === 2 && <Nft />}

      {screenNumber === 3 && <Wallet
        getUserAccounts={getUserAccounts}
        accounts={accounts}
        createNetwork={createNetwork}
        network={network}
        balance={balance}
        addAICCoinsToMyAccount={addAICCoinsToMyAccount}
        setSreenNumber={setSreenNumber}
        coinSymbol={configJson[SYMBOL_CONST]}
      />}

      {screenNumber === 4 && <Scan
        accounts={accounts}
        network={network}
        balance={balance}
        setSreenNumber={setSreenNumber}
        visitStation={visitStation}
        setErr={setErr}
      />}

      {screenNumber === 5 && <Help />}

      {screenNumber > 0 && <Nav
        screenNumber={screenNumber}
        setSreenNumber={setSreenNumber}
      />}

      {screenNumber === 6 && <AddNftTokenInstruction
        coidId={coidId}
        contractAddress={configJson[CONTRACT_ADDRESS_CONST]}
      />}

    </main >
  );
}

export default App
