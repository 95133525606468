import React, { useState, useEffect } from 'react'

// translation
import { translation } from '../utils/translation'

// const
import { WEB_URL_NAME, LANGUAGE_LOCAL_STOAGE, PL, UA, EN, } from '../utils/const'

import { copyToClipboard } from '../utils/copyToClipboard'

// svg
import { ReactComponent as Ok } from '../assets/icons/ok.svg'
import { ReactComponent as ArrowLeft } from '../assets/icons/chevron-left.svg'
import { ReactComponent as ArrowRight } from '../assets/icons/chevron-right.svg'
import { ReactComponent as Bulb } from '../assets/icons/bulb.svg'

// img
import appstore from '../assets/Config/appstore.png'
import googleplay from '../assets/Config/googleplay.png'
import metamaskIcon from '../assets/Config/metamask.png'

import step_A_PL from '../assets/Config/step_utworz_portfel/step_A@2x.png'
import step_B_PL from '../assets/Config/step_utworz_portfel/step_B@2x.png'
import step_C_PL from '../assets/Config/step_utworz_portfel/step_C@2x.png'
import step_D_PL from '../assets/Config/step_utworz_portfel/step_D@2x.png'
import step_E_PL from '../assets/Config/step_utworz_portfel/step_E@2x.png'
import step_F_PL from '../assets/Config/step_utworz_portfel/step_F@2x.png'
import step_G_1_PL from '../assets/Config/step_utworz_portfel/step_G_1@2x.png'
import step_G_2_PL from '../assets/Config/step_utworz_portfel/step_G_2@2x.png'
import step_H_PL from '../assets/Config/step_utworz_portfel/step_H@2x.png'
import step_I_PL from '../assets/Config/step_utworz_portfel/step_I@2x.png'

import step_A_UA from '../assets/Config/step_utworz_portfel/step_A_UA.png'
import step_B_UA from '../assets/Config/step_utworz_portfel/step_B_UA.png'
import step_C_UA from '../assets/Config/step_utworz_portfel/step_C_UA.png'
import step_D_UA from '../assets/Config/step_utworz_portfel/step_D_UA.png'
import step_E_UA from '../assets/Config/step_utworz_portfel/step_E_UA.png'
import step_F_UA from '../assets/Config/step_utworz_portfel/step_F_UA.png'
import step_G_1_UA from '../assets/Config/step_utworz_portfel/step_G_1_UA.png'
import step_G_2_UA from '../assets/Config/step_utworz_portfel/step_G_2_UA.png'
import step_H_UA from '../assets/Config/step_utworz_portfel/step_H_UA.png'
import step_I_UA from '../assets/Config/step_utworz_portfel/step_I_UA.png'

import step_A_EN from '../assets/Config/step_utworz_portfel/step_A_EN.png'
import step_B_EN from '../assets/Config/step_utworz_portfel/step_B_EN.png'
import step_C_EN from '../assets/Config/step_utworz_portfel/step_C_EN.png'
import step_D_EN from '../assets/Config/step_utworz_portfel/step_D_EN.png'
import step_E_EN from '../assets/Config/step_utworz_portfel/step_E_EN.png'
import step_F_EN from '../assets/Config/step_utworz_portfel/step_F_EN.png'
import step_G_1_EN from '../assets/Config/step_utworz_portfel/step_G_1_EN.png'
import step_G_2_EN from '../assets/Config/step_utworz_portfel/step_G_2_EN.png'
import step_H_EN from '../assets/Config/step_utworz_portfel/step_H_EN.png'
import step_I_EN from '../assets/Config/step_utworz_portfel/step_I_EN.png'

import step_A_web_PL from '../assets/Config/step_web/step_A@2x.png'
import step_B_web_PL from '../assets/Config/step_web/step_B@2x.png'
import step_C_web_PL from '../assets/Config/step_web/step_C@2x.png'

import step_A_web_UA from '../assets/Config/step_web/step_A_UA.png'
import step_B_web_UA from '../assets/Config/step_web/step_B_UA.png'
import step_C_web_UA from '../assets/Config/step_web/step_C_UA.png'

import step_A_web_EN from '../assets/Config/step_web/step_A_EN.png'
import step_B_web_EN from '../assets/Config/step_web/step_B_EN.png'
import step_C_web_EN from '../assets/Config/step_web/step_C_EN.png'


const screensItems = [
    { id: 1, description: 'Konfiguracja portfela wirtualnego MetaMask', name: 'Wprowadzenie', },
    { id: 2, description: 'Pobranie aplikacji', name: 'Pobranie aplikacji', },
    { id: 3, description: 'Stworzenie portfela', name: 'Stworzenie portfela' },
    { id: 4, description: 'Otwarcie aplikacji AIC20 w portfelu MetaMask', name: 'Przejście do MetaMask', },
]

const Config = ({ setSreenNumber }) => {

    const language = localStorage.getItem(LANGUAGE_LOCAL_STOAGE) ?? PL

    const [screenNrVisible, setScreenNrVisible] = useState(1)
    useEffect(() => { window.scrollTo({ top: 0 }) }, [screenNrVisible])


    return (
        <section className='config'>

            {/* NAV TOP  */}
            <nav className='config__nav'>

                <div className='config__nav_top'>

                    <div className='config__nav_oneSide'>
                        {screensItems.map(item => {
                            return (
                                <div key={item.id} className='config__nav_item_container' >
                                    {screenNrVisible >= item.id &&
                                        <p
                                            className='config__nav_item_number'
                                            style={screenNrVisible === item.id ? { color: 'white', background: 'var(--colorBright)' } : {}}>
                                            {screenNrVisible === item.id ? item.id : <Ok />}
                                        </p>}
                                    {item.id === screenNrVisible && <p className='config__nav_item_text'>{translation(item.name)}</p>}
                                </div>
                            )
                        })}
                    </div>


                    <div className='config__nav_oneSide'>
                        {screensItems.map(item => {
                            return (
                                <div key={item.id} className='config__nav_item_container' >
                                    {screenNrVisible < item.id && <p className='config__nav_item_number'>{item.id}</p>}
                                </div>
                            )
                        })}
                    </div>

                </div>

                <div className='config__nav_bottom'>
                    {screensItems.map(i => {
                        return (
                            <div key={i.id} className='config__nav_bottom_item' style={screenNrVisible >= i.id ? { background: 'var(--colorBright)' } : {}}></div>
                        )
                    })}
                </div>
            </nav>



            {/* MIDDLE  */}
            <section className='config__middle'>

                {/* MIDDLE header  */}
                <div className='config__middle__header'>
                    <p className='config__middle__header_top_text'>{screenNrVisible}/4</p>
                    <p className='config__middle__header_bottom_text'>
                        {screenNrVisible === 1 && translation(screensItems[0].description)}
                        {screenNrVisible === 2 && translation(screensItems[1].description)}
                        {screenNrVisible === 3 && translation(screensItems[2].description)}
                        {screenNrVisible === 4 && translation(screensItems[3].description)}
                    </p>
                </div>

                {/* MIDDLE content  */}
                <div className='config__middle__content'>

                    {screenNrVisible === 1 &&
                        <div className='config__middle__content_container'>
                            <p className='text margin1UpDown'>{translation('Za chwilę przeprowadzimy Cię krok po kroku przez proces stworzenia i konfiguracji wirtualnego portfela.')}</p>
                            <p className='text margin1UpDown textBold'>{translation('Nie wiąże się to z żadnymi kosztami i jest całkowicie bezpieczne.')}</p>
                            <p className='text margin1UpDown'>{translation('Ukończenie tego procesu jest konieczne, aby zacząć zbierać żetony.')}</p>
                        </div>}

                    {screenNrVisible === 2 &&
                        <div className='config__middle__content_container'>
                            <p className='text margin1UpDown'>{translation('Aby stworzyć wirtualny portfel, trzeba najpierw pobrać aplikację ze sklepu.')}</p>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <a href='https://apps.apple.com/pl/app/metamask-blockchain-wallet/id1438144202?l=pl'
                                    target='_blank'
                                    rel="noreferrer"
                                    className='config__middle__content_img'>
                                    <img className='img config__img' src={appstore} alt='appstore' />
                                    <p className='config__middle__content_img_text'>{translation('Pobierz aplikację MetaMask ze sklepu AppStore')}</p>
                                </a>
                                <a href='https://play.google.com/store/apps/details?id=io.metamask'
                                    target='_blank'
                                    rel="noreferrer"
                                    className='config__middle__content_img'>
                                    <img className='img config__img' src={googleplay} alt='googleplay' />
                                    <p className='config__middle__content_img_text'>{translation('Pobierz aplikację MetaMask ze sklepu GooglePlay')}</p>
                                </a>
                            </div>
                            <p className='text margin1UpDown'>{translation('Po zainstalowaniu MetaMask powróć do aplikacji AIC20 i przejdź do kroku 3.')}</p>
                        </div>}

                    {screenNrVisible === 3 &&
                        <div className='config__middle__content_container'>
                            <p className='text margin1UpDown'>{translation('Teraz pokażemy Ci krok po kroku jak utworzyć wirtualny portfel.')}</p>
                            <p className='text margin1UpDown'>{translation('Jeśli nie potrzebujesz tak szczegółowej instrukcji, możesz przejść od razu do kroku 4. Pamiętaj jednak o zapisaniu')} <span className='textBold'>Secret Recovery Phrase!</span></p>

                            <h3 className='title margin2Up'>{translation('A. Ekran powitalny')}</h3>
                            <p className='text margin1UpDown'>{translation('Aby przejść dalej klikamy w przycisk')} <span className='config__middle_textGreen'>Get</span>.</p>
                            {language === PL && <img className='img' src={step_A_PL} alt='step_A' />}
                            {language === UA && <img className='img' src={step_A_UA} alt='step_A' />}
                            {language === EN && <img className='img' src={step_A_EN} alt='step_A' />}

                            <h3 className='title margin2Up'>{translation('B. Ekran logowania')}</h3>
                            <p className='text margin1UpDown'>{translation('Na tym ekranie możemy zalogować się do portfela lub otworzyć nowy.')}</p>
                            <p className='text margin1UpDown'>{translation('Aby założyć portfel klikamy w przycisk')} <span className='config__middle_textGreen'>Create a new wallet</span>.</p>
                            {language === PL && <img className='img' src={step_B_PL} alt='step_B' />}
                            {language === UA && <img className='img' src={step_B_UA} alt='step_B' />}
                            {language === EN && <img className='img' src={step_B_EN} alt='step_B' />}

                            <h3 className='title margin2Up'>{translation('C. Zgoda na zbieranie podstawowych danych')}</h3>
                            <p className='text margin1UpDown'>{translation('Na tym ekranie możemy wybrać, czy chcemy, aby MetaMask zbierał podstawowe, anonimowe dane o korzystaniu z portfela.')}</p>
                            <p className='text margin1UpDown'>{translation('MetaMask nie będzie zbierać danych osobowych ani danych o transakcjach.')}</p>
                            <p className='text margin1UpDown'>{translation('Zalecamy wybór opcji')} <span className='config__middle_textGreen'>No thanks</span>.</p>
                            {language === PL && <img className='img' src={step_C_PL} alt='step_C' />}
                            {language === UA && <img className='img' src={step_C_UA} alt='step_C' />}
                            {language === EN && <img className='img' src={step_C_EN} alt='step_C' />}

                            <h3 className='title margin2Up'>{translation('D. Ustawienie hasła')}</h3>
                            <p className='text margin1UpDown'>{translation('Na tym ekranie tworzymy hasło, które będzie zabezpieczać portfel')} <span className='textBold'>{translation('tylko na tym konkretnym urządzeniu.')}</span></p>
                            <p className='text margin1UpDown'>{translation('Hasło wpisujemy w rubryce')} <span className='config__middle_textGreen'>New password</span> {translation('oraz powtarzamy to samo hasło w rubryce')} <span className='config__middle_textGreen'>Confirm password</span>.</p>
                            <div className='text margin1UpDown config__middle_hint'>
                                <Bulb className='svg' />
                                <p>{translation('Hasło musi mieć długość co najmniej 8 znaków.')}</p>
                            </div>
                            <p className='text margin1UpDown'>{translation('Nie ma możliwości odzyskania hasła, gdy je zapomnimy. Musimy zaznaczyć, że jesteśmy tego świadomi.')}</p>
                            <p className='text margin1UpDown'>{translation('Aby przejść dalej po uzupełnieniu danych klikamy w przycisk')} <span className='config__middle_textGreen'>Create password</span>.</p>
                            {language === PL && <img className='img' src={step_D_PL} alt='step_D' />}
                            {language === UA && <img className='img' src={step_D_UA} alt='step_D' />}
                            {language === EN && <img className='img' src={step_D_EN} alt='step_D' />}

                            <h3 className='title margin2Up'>{translation('E. Zabezpiecz swój portfel')}</h3>
                            <p className='text margin1UpDown'>{translation('W tym ekranie rozpoczyna się konfiguracja zabezpieczeń portfela.')}</p>
                            <p className='text margin1UpDown'>{translation('Zabezpieczenie nazywane jest Secret Recovery Phrase. Jest to zestaw 12 losowo wygenerowanych słów, które są unikalne dla każdego portfela.')}</p>
                            <div className='text margin1UpDown config__middle_hint'>
                                <Bulb className='svg' />
                                <p className='textBold'>Secret Recovery Phrase</p>
                                <p>{translation('To sekretny kod, który pomoże Ci na wypadek utraty dostępu do portfela lub podczas uruchomienia go na innym urządzeniu.')}</p>
                            </div>
                            <p className='text margin1UpDown'>{translation('Aby przejść dalej klikamy w przycisk')} <span className='config__middle_textGreen'>Start</span>.</p>
                            {language === PL && <img className='img' src={step_E_PL} alt='step_E' />}
                            {language === UA && <img className='img' src={step_E_UA} alt='step_E' />}
                            {language === EN && <img className='img' src={step_E_EN} alt='step_E' />}

                            <h3 className='title margin2Up'>{translation('F. Dowiedz się więcej o zabezpieczeniach')}</h3>
                            <p className='text margin1UpDown'>{translation('Na tym ekranie znajdują się dodatkowe informacje dotyczące Secret Recovery Phrase.')}</p>
                            <ul className='text config__middle_ul margin1UpDown'>
                                <li>{translation('Jest to bardzo bezpieczne rozwiązanie')}</li>
                                <li>{translation('Należy je przepisać w takiej samej kolejności, w jakiej się wyświetlą')}</li>
                                <li>{translation('Należy schować je w miejscu, o którym wiedzieć i pamiętać będziesz tylko Ty')}</li>
                            </ul>
                            <p className='text margin1UpDown'>{translation('Aby przejść dalej klikamy w przycisk')} <span className='config__middle_textGreen'>Start</span>.</p>
                            {language === PL && <img className='img' src={step_F_PL} alt='step_F' />}
                            {language === UA && <img className='img' src={step_F_UA} alt='step_F' />}
                            {language === EN && <img className='img' src={step_F_EN} alt='step_F' />}

                            <h3 className='title margin2Up'>{translation('G. Zapisz swoje Secret Recovery Phrase')}</h3>
                            <p className='text margin1UpDown'>{translation('Na tym ekranie znajduje się Twoja Secret Recovery Phrase, czyli zestaw 12 słów.')}</p>
                            <p className='text margin1UpDown'>{translation('Aby je wyświetlić, musisz kliknąć')} <span className='config__middle_textGreen'>View</span>.</p>
                            <p className='text margin1UpDown'>{translation('Następnie')} <span className='textBold'>{translation('zapisz je w takiej samej kolejności')}</span>{translation(', w jakiej Ci się wyświetlą i schowaj w bezpiecznym miejscu.')}</p>
                            <p className='text margin1UpDown'>{translation('Funkcja zrzutu ekranu może nie zadziałać w zależności od urządzenia z jakiego korzystasz.')}</p>
                            <div className='text margin1UpDown config__middle_hint'>
                                <Bulb className='svg' />
                                <p className='textBold'>{translation('Koniecznie zapisz sobie wszystkie słowa w takiej samej kolejności')}</p>
                                <p>{translation('Jeśli zgubisz Secret Recovery Phrase, nie będzie możliwości odzyskania portfela!')}</p>
                            </div>
                            <p className='text margin1UpDown'>{translation('Aby przejść dalej, po odkryciu i zapisaniu zestawu słów klikamy w przycisk')} <span className='config__middle_textGreen'>Continue</span>.</p>
                            {language === PL && <img className='img' src={step_G_1_PL} alt='step_G_1' />}
                            {language === UA && <img className='img' src={step_G_1_UA} alt='step_G_1' />}
                            {language === EN && <img className='img' src={step_G_1_EN} alt='step_G_1' />}
                            {language === PL && <img className='img' src={step_G_2_PL} alt='step_G_2' />}
                            {language === UA && <img className='img' src={step_G_2_UA} alt='step_G_2' />}
                            {language === EN && <img className='img' src={step_G_2_EN} alt='step_G_2' />}

                            <h3 className='title margin2Up'>{translation('H. Zatwierdź swoje Secret Recovery Phrase')}</h3>
                            <p className='text margin1UpDown'>{translation('Na tym ekranie musimy potwierdzić nasz zestaw słów zabezpieczających.')}</p>
                            <p className='text margin1UpDown'>{translation('W dolnej części ekranu znajdują się nasze słowa. Należy po kolei wybrać odpowiednie słowa zgodnie kolejnością naszego Secret Recovery Phrase.')}</p>
                            <p className='text margin1UpDown'>J{translation('Jeśli się pomylimy, klikamy w słowo, aby je usunąć z listy.')}</p>
                            <p className='text margin1UpDown'>{translation('Aby przejść dalej, po odkryciu i zapisaniu zestawu słów klikamy w przycisk')} <span className='config__middle_textGreen'>Complete Backup</span>.</p>
                            {language === PL && <img className='img' src={step_H_PL} alt='step_H' />}
                            {language === UA && <img className='img' src={step_H_UA} alt='step_H' />}
                            {language === EN && <img className='img' src={step_H_EN} alt='step_H' />}

                            <h3 className='title margin2Up'>{translation('I. Zakończenie konfiguracji')}</h3>
                            <p className='text margin1UpDown'>{translation('Gratulacje! Udało Ci się założyć portfel.')}</p>
                            <p className='text margin1UpDown'>{translation('Pamiętaj, aby schować Secret Recovery Phrase w bezpiecznym miejscu.')}</p>
                            <p className='text margin1UpDown'>{translation('Secret Recovery Phrase możesz podejrzeć w ustawieniach MetaMaska')}</p>
                            <div className='text margin1UpDown config__middle_hint'>
                                <Bulb className='svg' />
                                <p>{translation('Klikając')} <span className='config__middle_textGreen'>Leave yourself a hint</span> {translation('możesz także zostawić sobie wskazówkę dotyczącą Secret Revocery Phrase, np. o miejscu, gdzie schowasz swoje słowa.')} <span className='textBold'>{translation('Nie wpisuj tam jednak treści Secret Revocery Phrase!')}</span></p>
                            </div>
                            <p className='text margin1UpDown'>{translation('Aby przejść do portfela, klikamy w')} <span className='config__middle_textGreen'>Done</span>.</p>
                            {language === PL && <img className='img' src={step_I_PL} alt='step_I' />}
                            {language === UA && <img className='img' src={step_I_UA} alt='step_I' />}
                            {language === EN && <img className='img' src={step_I_EN} alt='step_I' />}

                        </div>}

                    {screenNrVisible === 4 &&
                        <div className='config__middle__content_container'>
                            <p className='text margin1UpDown'>{translation('Aby możliwe było skanowanie i zbieranie żetonów, trzeba otworzyć Stacyjkowo przez przeglądarkę wewnętrzną w aplikacji MetaMask.')}</p>
                            <p className='text margin1UpDown'>{translation('Poniżej znajdziesz instrukcję jak to zrobić.')}</p>

                            <div className='config__middle__content_container_web'>
                                <p className='config__middle__content_container_web_point text margin1UpDown'>{translation('A.')}</p>
                                <p className='text margin1UpDown'>{translation('Otwórz aplikację MetaMask i wysuń')} <span className='config__middle_textGreen'>menu boczne</span>{translation(', klikając w ikonę w lewym górnym rogu')}</p>
                            </div>
                            {language === PL && <img className='img' src={step_A_web_PL} alt='step_A_web' />}
                            {language === UA && <img className='img' src={step_A_web_UA} alt='step_A_web' />}
                            {language === EN && <img className='img' src={step_A_web_EN} alt='step_A_web' />}

                            <div className='config__middle__content_container_web'>
                                <p className='config__middle__content_container_web_point text margin1UpDown'>{translation('B.')}</p>
                                <p className='text margin1UpDown'>{translation('Z wysuniętego menu wybierz pozycję')} <span className='config__middle_textGreen'>Browser</span>.</p>
                            </div>
                            {language === PL && <img className='img' src={step_B_web_PL} alt='step_B_web' />}
                            {language === UA && <img className='img' src={step_B_web_UA} alt='step_B_web' />}
                            {language === EN && <img className='img' src={step_B_web_EN} alt='step_B_web' />}

                            <div className='config__middle__content_container_web'>
                                <p className='config__middle__content_container_web_point text margin1UpDown'>{translation('C.')}</p>
                                <p className='text margin1UpDown'>{translation('Teraz kliknij w pole adresu i wprowadź poniższy adres strony i do niego przejdź.')}</p>
                            </div>
                            <div className='text margin1UpDown config__middle_hint config__middle__content_container_web_displayFlex'>
                                <p>{WEB_URL_NAME}</p>
                                <button
                                    className='btn_bright_small2'
                                    onClick={() => copyToClipboard(WEB_URL_NAME)}>{translation('Kopiuj')}</button>
                            </div>
                            {language === PL && <img className='img' src={step_C_web_PL} alt='step_C_web' />}
                            {language === UA && <img className='img' src={step_C_web_UA} alt='step_C_web' />}
                            {language === EN && <img className='img' src={step_C_web_EN} alt='step_C_web' />}

                            <div className='config__middle__content_container_web'>
                                <p className='config__middle__content_container_web_point text margin1UpDown'>{translation('D.')}</p>
                                <p className='text margin1UpDown'>{translation('Od teraz otwieraj AIC20 w przeglądarce wewnętrznej portfela MetaMask.')}</p>
                            </div>
                            <button onClick={() => window.location.replace('metamask://')} className='btn_clear' style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <img className='img config__img_big' src={metamaskIcon} alt='metamask_circle' />
                            </button>
                            <div className='text margin1UpDown config__middle_hint'>
                                <Bulb className='svg' />
                                <p>{translation('Obsługa AIC20 przez przeglądarkę w MetaMask jest konieczna, aby zbierać żetony.')}</p>
                            </div>
                        </div>}
                </div>

            </section>



            {/* NAV BOTTOM  */}
            <nav className='config__navigationBottom'>
                <div className='config__navigationBottom_line'></div>
                <div className='config__navigationBottom_container'>
                    <button
                        onClick={() => screenNrVisible === 1 ? setSreenNumber(1) : setScreenNrVisible(prevState => prevState - 1)}
                        className='btn_clear' >
                        <ArrowLeft className='svg' />{screenNrVisible === 1 ? translation('Wyjdź') : translation('Wstecz')}
                    </button>
                    <button
                        onClick={() => screenNrVisible === 4 ? setSreenNumber(3) : setScreenNrVisible(prevState => prevState + 1)}
                        className='btn_dark_small' >
                        {screenNrVisible === 4 ? `${translation('Rozpocznij zabawę')}` : `${translation('Przejdź do kroku')} ${screenNrVisible + 1} `} <ArrowRight className='svg' />
                    </button>
                </div>
            </nav>
        </section>
    )
}

export default Config
